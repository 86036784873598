.info {
  max-width: 360px;
  margin: 0 auto;
  padding: 70px 0 30px;

  .icon {
    color: $default;

    > i {
      font-size: $h1-font-size;
    }
  }
  .info-title {
    color: $white;
    margin: 0.875rem * 2 0 0.875rem;
  }
  p {
    color: $opacity-gray-8;
    line-height: 24px;
  }
}

hr {
  width: 10%;
  height: 1px;
  border-radius: 3px;
  margin-left: 0;

  &.line-primary {
    background-color: $primary;
  }
  &.line-info {
    background-color: $info;
  }
  &.line-success {
    background-color: $success;
  }
  &.line-warning {
    background-color: $warning;
  }
  &.line-danger {
    background-color: $danger;
  }
  &.line-deafult {
    background-color: $default;
  }
}

.info-horizontal {
  .icon {
    float: left;
    margin-top: 24px;
    margin-right: 10px;

    > i {
      font-size: $h1-font-size;
    }
  }
  .description {
    overflow: hidden;
  }
}

.icon {
  &.icon-primary {
    color: $primary;
  }
  &.icon-info {
    color: $info;
  }
  &.icon-success {
    color: $success;
  }
  &.icon-warning {
    color: $warning;
  }
  &.icon-danger {
    color: $danger;
  }
}
