.footer {
  padding: 50px 0;
  border-top: 2px solid #435db5;
  background: radial-gradient(
    ellipse at bottom,
    #292d61 30%,
    $background-black 80%
  );

  [class*="container-"] {
    padding: 0;
  }

  .nav {
    display: block;
    float: left;
    margin-bottom: 0;
    padding-top: 25px;
    list-style: none;
  }

  .nav-item {
    display: block;
  }

  .nav-link {
    color: $white;
    padding: 10px 0;
    font-size: $font-size-sm;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  .title {
    text-align: left !important;
  }

  .copyright {
    font-size: $font-size-sm;
    line-height: 1.8;
    color: $white;
  }

  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

@media screen and (max-width: 991px) {
  .footer {
    padding-left: 0px;

    .copyright {
      text-align: right;
      margin-right: 15px;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    .copyright {
      float: right;
      padding-right: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer {
    nav {
      display: block;
      margin-bottom: 5px;
      float: none;
    }
  }
}

@media screen and (max-width: 576px) {
  .footer {
    text-align: center;
    .copyright {
      text-align: center;
    }

    .nav {
      float: none;
      padding-left: 0;
    }
  }
}
