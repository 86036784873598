$main-bg:         #fff !default;
$custom-range-bg: #eee !default;

$main-box-shadow: #aaa !default;

$font-color-01:   #fff !default;
$font-color-02:   #9aaec1 !default;
$font-color-03:   #54708b !default;

$border-color:    #e9edf0 !default;
$highlighted:     #e9edf0 !default;

$btn-bg:          #e9edf0 !default;
$btn-bg-hover:    #d5dadd !default;

$btn-bg2:         #9aaec1 !default;
$btn-bg2-hover:   #54708b !default;

$theme-gray:      #777 !default;
$theme-green:     #5cb85c !default;
$theme-blue:      #5bc0de !default;
$theme-dark-blue: #337ab7 !default;
$theme-red:       #d9534f !default;
$theme-orange:    #f0ad4e !default;

$disabled-background:  rgba(221, 221, 221, 0.3) !default;
$disabled-color:       #f5f5f5 !default;

$hover-theme-default: #6F6E6E;
$hover-theme-green: #218838;
$hover-theme-blue: #3AB3D7;
$hover-theme-dark-blue: #2C6FA9;
$hover-theme-orange: #FFAC35;
$hover-theme-red: #E33732;

$active-theme-default: #616161;
$active-theme-green: #1e7e34;
$active-theme-blue: #2AA8CD;
$active-theme-dark-blue: #266498;
$active-theme-orange: #ED9C29;
$active-theme-red: #D23531;

$theme-list: (
  'default': $theme-gray,
  'green': $theme-green,
  'blue': $theme-blue,
  'dark-blue': $theme-dark-blue,
  'red': $theme-red,
  'orange': $theme-orange,
) !default;
